var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-5 ma-3 white rounded-lg"},[_c('div',{staticClass:"subheading grey--text mb-9"},[_vm._v("Lead List")]),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6","offset":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Filter","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":_vm.paginationOptions,"search":_vm.search,"item-key":"id","options":_vm.pagination,"loading":_vm.loading},on:{"click:row":_vm.toLeadDetails,"pagination":_vm.onPagination},scopedSlots:_vm._u([{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPhoneNum( item.phone ))+" ")]}},{key:"item.event_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate( item.event_date ))+" ")]}},{key:"item.date_created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate( item.date_created, 'YYYY-MM-DD @ hh:mm a' ))+" ")]}},{key:"item.contacted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.contacted == 1 ? 'Yes' : 'No')+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }